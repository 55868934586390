import { on } from 'delegated-events'
import { slideUp, slideDown } from '@common/slide'

const els = document.querySelectorAll('.element-accordionlist')

export default {
  can: () => els.length,
  run: () => {
    const observer = new MutationObserver((mutationList) => {
      for (const mutation of mutationList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'aria-expanded') {
          els.forEach((el) => {
            const opened = el.querySelectorAll('.accordion-list-item[aria-expanded="true"]').length
            const toggle = el.querySelector('.accordion-toggle-all')

            if (opened > 0) {
              toggle.textContent = 'Close All'
              toggle.setAttribute('data-type', 'close')
            }

            if (opened === 0) {
              toggle.textContent = 'Open All'
              toggle.setAttribute('data-type', 'open')
            }
          })
        }
      }
    })

    els.forEach((el) => {
      el.querySelectorAll('.accordion-list-item').forEach((item) => {
        observer.observe(item, { attributes: true })
      })
    })

    on('click', '.accordion-toggle-all', (e) => {
      const holder = e.target.closest('.element-accordionlist')
      const items = holder.querySelectorAll('.accordion-list-item')
      const type = e.target.dataset.type

      if (type === 'open') {
        items.forEach(async (item) => {
          const target = document.getElementById(item.getAttribute('aria-controls'))
          item.setAttribute('aria-expanded', 'true')
          item.parentNode.classList.add('is-active')

          await slideDown(target)
        })
      }

      if (type === 'close') {
        items.forEach(async (item) => {
          const target = document.getElementById(item.getAttribute('aria-controls'))
          item.setAttribute('aria-expanded', 'false')
          item.parentNode.classList.remove('is-active')

          await slideUp(target)
        })
      }
    })
  },
}
