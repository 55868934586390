import Swiper, { Autoplay } from 'swiper'

Swiper.use([Autoplay])

const sliders = document.querySelectorAll('.stats-slider')

const init = () => {
  sliders.forEach((slider) => {
    const container = slider.querySelector('.swiper')
    const numSlides = slider.querySelectorAll('.swiper-slide').length

    if (numSlides > 1) {
      new Swiper(container, {
        autoplay: {
          delay: 3000,
        },
        slidesPerView: 2,
        breakpoints: {
          768: {
            autoplay: false,
            slidesPerView: numSlides,
          },
        },
      })
    }
  })
}

export default {
  can: () => sliders.length,
  run: init,
}
