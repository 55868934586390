import Swiper, { Navigation, Pagination } from 'swiper'

Swiper.use([Navigation, Pagination])

const sliders = document.querySelectorAll('.testimonials-slider')

const init = () => {
  sliders.forEach((slider) => {
    const container = slider.querySelector('.swiper')
    const numSlides = slider.querySelectorAll('.swiper-slide').length

    if (numSlides > 1) {
      new Swiper(container, {
        loop: true,
        slidesPerView: 'auto',
        loopedSlides: numSlides,
        allowTouchMove: false,
        simulateTouch: false,
        pagination: {
          el: slider.querySelector('.slider-pagination'),
          clickable: true,
        },
        navigation: {
          nextEl: slider.querySelector('.slider-control-next'),
          prevEl: slider.querySelector('.slider-control-prev'),
        },
      })
    }
  })
}

export default {
  can: () => sliders.length,
  run: init,
}
