import Swiper, { Autoplay, EffectFade } from 'swiper'

Swiper.use([Autoplay, EffectFade])

const pageHeader = document.querySelector('.page-header')

export default {
  can: () => pageHeader,
  run: () => {
    const pageHeaderImageSlider = pageHeader.querySelector('.page-header-image-slider')

    if (pageHeaderImageSlider) {
      const numSlides = pageHeaderImageSlider.querySelectorAll('.swiper-slide').length

      if (numSlides > 1) {
        new Swiper(pageHeaderImageSlider.querySelector('.swiper'), {
          slidesPerView: 1,
          speed: 1000,
          effect: 'fade',
          simulateTouch: false,
          fadeEffect: {
            crossFade: true,
          },
          autoplay: {
            delay: 3000,
          },
        })
      }
    }
  },
}
