export default {
  can: () => document.body.classList.contains('pagetype-homepage'),
  run: () => {
    const typeField = document.querySelector('.type-field')
    const typedText = document.querySelector('.type-text')
    const cursor = document.querySelector('.type-cursor')
    const learnMoreAnchor = document.querySelector('.learn-more-anchor')

    if (!typeField) {
      return
    }

    const textArray = JSON.parse(typeField.dataset.words)

    let textArrayIndex = 0
    let charIndex = 0

    const erase = () => {
      if (charIndex > 0) {
        cursor.classList.remove('blink')
        typedText.textContent = textArray[textArrayIndex]['title'].slice(0, charIndex - 1)
        charIndex--
        setTimeout(erase, 10)
      } else {
        cursor.classList.add('blink')
        textArrayIndex++
        if (textArrayIndex > textArray.length - 1) {
          textArrayIndex = 0
        }
        setTimeout(type, 500)
      }
    }

    const type = () => {
      if (charIndex <= textArray[textArrayIndex]['title'].length - 1) {
        cursor.classList.remove('blink')
        typedText.textContent += textArray[textArrayIndex]['title'].charAt(charIndex)
        charIndex++
        setTimeout(type, 80)

        const link = textArray[textArrayIndex].link
        learnMoreAnchor.href = link
      } else {
        cursor.classList.add('blink')
        setTimeout(erase, 1000)
      }
    }

    setTimeout(type, 1000)
  },
}
