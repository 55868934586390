import { on } from 'delegated-events'
import Swiper, { Autoplay, Pagination } from 'swiper'

Swiper.use([Autoplay, Pagination])

const els = document.querySelectorAll('.element-tabcontent')

export default {
  can: () => els.length,
  run: () => {
    on('click', '.element-tabcontent [data-tab-trigger]', (e) => {
      e.preventDefault()

      const element = e.target.closest('.element-tabcontent')
      const trigger = e.target.closest('[data-tab-trigger]')
      const target = document.getElementById(trigger.getAttribute('aria-controls'))

      element.querySelectorAll('[data-tab-trigger]').forEach((x) => x.setAttribute('aria-selected', false))
      element.querySelectorAll('[data-tab-panel]').forEach((x) => x.classList.add('hidden'))

      trigger.setAttribute('aria-selected', true)
      target.classList.remove('hidden')
    })

    els.forEach((el) => {
      el.querySelectorAll('.tab-slider').forEach((slider) => {
        const container = slider.querySelector('.swiper')
        const numSlides = slider.querySelectorAll('.swiper-slide').length

        if (numSlides > 1) {
          new Swiper(container, {
            autoplay: {
              delay: 4000,
            },
            loop: true,
            loopedSlides: numSlides,
            allowTouchMove: false,
            simulateTouch: false,
            slidesPerView: 1,
            pagination: {
              el: slider.querySelector('.slider-pagination'),
              clickable: true,
            },
            breakpoints: {
              768: {
                slidesPerView: 2,
              },
              1024: {
                enabled: false,
                loop: false,
                autoplay: false,
                slidesPerView: numSlides,
              },
            },
          })
        }
      })
    })
  },
}
