// Theme
import '@styles/index.css'
import { throttle } from 'lodash'

// Thirdparty
import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/effect-fade'

const handleFooter = () => {
  const ipaFooterPhoto = document.querySelector('.ipa-footer-photo')
  const ipaFooterCallout = document.querySelector('.ipa-footer-callout')
  const ipaFooterContent = document.querySelector('.ipa-footer-content')

  if (ipaFooterPhoto && ipaFooterCallout) {
    ipaFooterPhoto.style.marginBottom = null

    const diff = ipaFooterContent.getBoundingClientRect().top - ipaFooterPhoto.getBoundingClientRect().bottom

    if (diff > 0) {
      ipaFooterPhoto.style.marginBottom = `-${diff}px`
    }
  }
}

/*
 * Script loader
 *
 * Imports and runs all scripts in the `./scripts` directory
 * Scripts must have a default export with `can` and `run` functions
 *
 *    export default {
 *      can: () => boolean,
 *      run: function to run if `can` is true
 *    }
 */
document.addEventListener('DOMContentLoaded', () => {
  ;((r) => {
    r.keys()
      .reduce((modules, script) => {
        const module = r(script).default
        if (module) {
          modules.push(module)
        }
        return modules
      }, [])
      .map((module) => (module.can === true || module.can()) && module.run())
  })(require.context('./scripts', true, /^(?!.*(common|react)).*\.js$/))

  document.documentElement.classList.remove('_preload')

  handleFooter()

  window.addEventListener('resize', throttle(handleFooter, 250))
})
