import { on } from 'delegated-events'
import Swiper, { Autoplay, EffectFade } from 'swiper'

Swiper.use([Autoplay, EffectFade])

document.querySelectorAll('.element-lists').forEach((el) => {
  const mobileContainer = el.querySelector('.mobile-slider')
  const triggerContainer = el.querySelector('.trigger-slider')
  const listContainer = el.querySelector('.lists-slider')
  const smallIconContainer = el.querySelector('.small-icon-slider')
  const largeIconContainer = el.querySelector('.large-icon-slider')
  const numSlides = listContainer.querySelectorAll('.swiper-slide').length

  if (numSlides > 1) {
    new Swiper(mobileContainer.querySelector('.swiper'), {
      loop: true,
      slidesPerView: 'auto',
      loopedSlides: numSlides,
      navigation: {
        nextEl: mobileContainer.querySelector('.slider-control-next'),
        prevEl: mobileContainer.querySelector('.slider-control-prev'),
      },
      breakpoints: {
        768: {
          disable: true,
        },
      },
    })

    const smallIconSwiper = new Swiper(smallIconContainer.querySelector('.swiper'), {
      slidesPerView: 1,
      speed: 1000,
      effect: 'fade',
      simulateTouch: false,
      fadeEffect: {
        crossFade: true,
      },
    })

    const largeIconSwiper = new Swiper(largeIconContainer.querySelector('.swiper'), {
      slidesPerView: 1,
      speed: 500,
      effect: 'fade',
      simulateTouch: false,
      autoHeight: true,
      fadeEffect: {
        crossFade: true,
      },
    })

    new Swiper(triggerContainer.querySelector('.swiper'), {
      slidesPerView: 'auto',
      centeredSlides: true,
      autoplay: {
        delay: 3000,
      },
      breakpoints: {
        768: {
          direction: 'vertical',
          centeredSlides: false,
          disable: true,
          autoplay: false,
        },
      },
      on: {
        activeIndexChange: (swiper) => {
          const newIndex = swiper.realIndex

          if (window.matchMedia('(max-width: 767px)').matches) {
            listSwiper.slideTo(newIndex)
          }
        },
      },
    })

    const listSwiper = new Swiper(listContainer.querySelector('.swiper'), {
      slidesPerView: 1,
      speed: 500,
      effect: 'fade',
      simulateTouch: false,
      fadeEffect: {
        crossFade: true,
      },
      navigation: {
        nextEl: triggerContainer.querySelector('.trigger-slider-control-next'),
        prevEl: triggerContainer.querySelector('.trigger-slider-control-prev'),
      },
      loop: true,
      loopedSlides: numSlides,
      breakpoints: {
        768: {
          autoplay: {
            delay: 3000,
          },
        },
      },
      on: {
        activeIndexChange: (swiper) => {
          const newIndex = parseInt(swiper.realIndex)

          smallIconSwiper.slideTo(newIndex)
          largeIconSwiper.slideTo(newIndex)

          if (window.matchMedia('(min-width: 768px)').matches) {
            triggerContainer
              .querySelectorAll('.fake-swiper-slide-active')
              .forEach((x) => x.classList.remove('fake-swiper-slide-active'))
            triggerContainer.querySelector(`[data-index="${newIndex + 1}"]`).classList.add('fake-swiper-slide-active')
          }
        },
      },
    })

    on('click', '.trigger-slider [data-index]', (e) => {
      if (window.matchMedia('(min-width: 768px)').matches) {
        const item = e.target.closest('[data-index]')
        const index = parseInt(item.dataset.index) - 1
        listSwiper.autoplay.stop()
        listSwiper.slideTo(index)
      }
    })

    if (window.matchMedia('(min-width: 768px)').matches) {
      listSwiper.autoplay.start()
    }
  }
})
