import { on } from 'delegated-events'
import easydropdown from 'easydropdown-a11y'
import ajax from '@common/ajax'
import scrollTo from '@common/scroll-to'

export default {
  can: () =>
    document.body.classList.contains('pagetype-newspage') &&
    !document.body.classList.contains('pagetype-newspage-view'),
  run: () => {
    const newsSection = document.querySelector('.news-section')
    const pageItems = document.querySelector('.news-items')
    const form = document.querySelector('.news-form')

    let categoryDropdown

    const dropdown = form.querySelector('select[name="category"]')

    if (dropdown) {
      categoryDropdown = easydropdown(dropdown, {
        behavior: {
          openOnFocus: true,
          maxVisibleItems: 8,
          liveUpdates: true,
          useNativeUiOnMobile: false,
        },
        callbacks: {
          onSelect: (val) => {
            if (val) {
              form.querySelector('input[type="search"]').value = null

              if (form.requestSubmit) {
                form.requestSubmit()
              } else {
                form.dispatchEvent(new Event('submit'))
              }
            }
          },
        },
      })
    }

    on('submit', '.news-form', async (e) => {
      e.preventDefault()

      const formData = new FormData(form)

      for (let [name, value] of Array.from(formData.entries())) {
        if (value === '') formData.delete(name)
      }

      if (formData.has('search')) {
        formData.delete('category')

        if (dropdown) {
          dropdown.value = ''
        }
      }

      const params = new URLSearchParams(formData).toString()
      const endpoint = new URL(form.action)
      endpoint.search = `?${params}`

      window.history.replaceState({}, '', endpoint.toString())

      form.classList.add('is-filtered')
      pageItems.classList.add('is-filtered-loading')

      const res = await ajax.get(endpoint).text()

      pageItems.innerHTML = res
      pageItems.classList.remove('is-filtered-loading')

      scrollTo(newsSection, 24)
    })

    on('click', '.news-reset', () => {
      form.querySelectorAll('input').forEach((x) => (x.value = ''))

      if (categoryDropdown) {
        form.querySelectorAll('select').forEach((x) => (x.value = ''))
        categoryDropdown.refresh()
      }

      if (form.requestSubmit) {
        form.requestSubmit()
      } else {
        form.dispatchEvent(new Event('submit'))
      }

      form.classList.remove('is-filtered')
    })

    on('click', '.news-pagination-control', async (e) => {
      e.preventDefault()

      const url = e.target.closest('a').href

      pageItems.classList.add('is-filtered-loading')

      const res = await ajax.get(url).text()

      pageItems.innerHTML = res
      pageItems.classList.remove('is-filtered-loading')

      window.history.replaceState({}, '', url)

      scrollTo(newsSection, 24)
    })
  },
}
